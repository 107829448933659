import { Component } from 'react'
import { API } from 'aws-amplify'
import Form from "react-bootstrap/Form"
import moment from 'moment'
import QRCode from 'qrcode.react'
import TopMenu from '../components/topMenu'
import Carousel from 'react-bootstrap/Carousel'
import withRouter from '../components/withRouter'
import { FaArrowLeft, FaRegQuestionCircle, FaStar, FaHeart, FaUser, FaLink, FaRegCheckSquare, FaMapMarkedAlt, FaUserAltSlash, FaPlusSquare, FaPhone, FaFax } from 'react-icons/fa'
import { FaSquareTwitter, FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaYoutube, FaSquareXmark } from 'react-icons/fa6'
import { IoLogoVenmo } from 'react-icons/io5'
import { SiCashapp } from 'react-icons/si'
import { BsYelp, BsLinkedin } from 'react-icons/bs'
import { LuCalendarClock } from 'react-icons/lu'
import { MdOutlineAttachMoney, MdOutlineVerified, MdPublishedWithChanges, MdEmail, MdPublic, MdPublicOff, MdOutlineVerifiedUser } from 'react-icons/md'
import { GoUnverified } from 'react-icons/go'
import { BiMessageAdd } from 'react-icons/bi'
import { TbStatusChange } from 'react-icons/tb'
import { FcInvite } from 'react-icons/fc'
import Logo from '../images/GigLoca_Logo_Light.png'
import { getAddress } from '../helpers'
import './node.css'

class Contributor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSaving: false,
            route: null,
            bounce: false,
            data: null,
            node: null,
            displaySettings: false,
            id: null,
            refresh: false,
            displayRate: false,
            stars: null,
            more: null,
            comments: '',
            invite: '',
            userFailed: false,
            userPassed: false,
            ownerFailed: false,
            ownerPassed: false,
            lastInvite: '',
            msg: '',
            displayMsg: false,
            owner: '',
            connected: ''
        }

        this.handleEventSelect = this.handleEventSelect.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
    }

    componentDidMount() {
        const { path, search } = this.props
        const id = path.id || null
        const queryParams = new URLSearchParams(search.search)
        const route = search.pathname.toLowerCase() === `/venue/${id}` ? 'Venue' : 'Contributor'
        this.setState({ refresh: queryParams.get('refresh') || false, route, id })
        if (id) {
            if (this.props.myLoc) {
                this.getData(id, this.props.myLoc)
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.getData(id, { lat: position.coords.latitude, lng: position.coords.longitude })
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                }, err => {
                    this.getData(id)
                })
            }
        } else this.handleNavigate('')
    }

    handleNavigate(page) {
        if (this.state.id) this.props.onAddHistory(`/contributor/${this.state.id}`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleOnPrefSelect() {
        this.handleNavigate('pref')
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode !== 'fan') {
            this.props.setMode(mode)
            this.handleNavigate(`my/${mode}s`)
        }
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleEventSelect(eid) {
        this.handleNavigate(`event/${eid}`)
    }

    handleCancel() {
        this.setState({ displayRate: false, stars: this.state.node.irated || null, comments: this.state.node.comments || '' })
    }

    handleInvite() {
        if (this.state.node && this.state.invite.toLowerCase() !== this.state.lastInvite.toLowerCase()) {
            let body = { email: this.state.invite.toLowerCase(), type: 'onboard', node: this.state.node.name || '', link: `https://gigloca.com/${this.state.route}/${this.state.id}` }
            if (this.state.connected) body.connected = this.state.connected
            API.post('lt', `invite`, { body })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', connected: '', lastInvite: this.state.invite })
                })
                .catch(err => {
                    this.setState({ msg: err.error || 'Unable to send onboarding email.', userFailed: true, userPassed: false })
                })
        } else this.setState({ msg: "Duplicate user request.", userFailed: true, userPassed: false })
    }

    handleVerified(unmanaged) {
        this.setState({ isSaving: true })
        API.put('lt', `admin/${this.state.id}`, { body: { action: 'active', local: moment().format("YYYY-MM-DD HH:mm"), unmanaged } })
            .then(resp => {
                this.setState({ isSaving: false, data: { ...this.state.data, active: !this.state.data.active, managed: !unmanaged } })
            })
            .catch(e => {
                this.setState({ isSaving: false, msg: e.message, displayMsg: true })
            })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    handleChangeOwner() {
        if (this.state.owner.length > 7 && this.state.owner.indexOf('@') > 1 && this.state.owner.indexOf('.') > 3) {
            API.put('lt', `admin/${this.state.id}`, { body: { action: 'ownership', email: this.state.owner, local: moment().format("YYYY-MM-DD HH:mm") } })
                .then(resp => {
                    this.setState({ isSaving: false, ownerPassed: true })
                })
                .catch(e => {
                    this.setState({ isSaving: false, ownerFailed: true, ownerPassed: false })
                })
        }
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'invite':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, ""), userFailed: false, userPassed: false })
                break
            case 'owner':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, ""), userFailed: false, userPassed: false })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
        }
    }

    ratingIsValid() {
        return this.state.stars || this.state.node.irated
    }

    getData(id, coord) {
        //you are here, need to figure out how to get the contributor out of the resp.data
        API.get('lt', `nodes/${id}${coord ? `?lat=${coord.lat}&lng=${coord.lng}` : ''}`)
            .then(resp => {
                if (resp.data) {
                    let node = resp.data
                    let data = resp.data
                    if (node) {
                        //this.setState({data, node})
                        this.setState({ data, node, id, stars: node.irated || null, comments: node.comments || '', owner: node.createdby && node.createdby.email ? node.createdby.email : '' })
                    } else this.handleNavigate('')
                }
            })
            .catch(e => {
                console.log(e)
                this.handleNavigate('')
            })
    }

    getSelectedStarsArray(s) {
        let stars = s || this.state.stars || 0
        let selected = []
        for (let x = 0; x < stars; x++) {
            selected.push(x + 1)
        }
        return selected
    }

    getUnselectedStarsArray(s) {
        let stars = s || this.state.stars || 1
        let unselected = []
        for (let x = parseInt(stars); x < 5; x++) {
            let s = x + 1
            unselected.push(s)
        }
        return unselected
    }

    getValidation(field) {
        if (field === 'connected') {
            return true
        } else if (field === 'owner') {
            return this.state.owner.length > 2 && this.state.owner.indexOf('@') > 0 && this.state.owner.indexOf('.') > 1
        } else if (field === 'invite') {
            return this.state.invite.length > 2 && this.state.invite.indexOf('@') > 0 && this.state.invite.indexOf('.') > 1
        }
    }

    getBGIcon() {
        if (this.state.node && this.state.node.category) {
            switch (this.state.data.category) {

                default:
                    return <FaUser />
            }
        } else return <FaRegQuestionCircle />
    }

    getSocialIcon(link) {
        let thisLink = link || this.state.link
        if (thisLink.length > 8) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            if (t.test(thisLink)) {
                return <div style={{ marginTop: '10px' }} className='Node-Flex'><FaSquareTwitter /><FaSquareXTwitter /></div>
            } else if (fb.test(thisLink)) {
                return <FaSquareFacebook />
            } else if (inst.test(thisLink)) {
                return <FaSquareInstagram />
            } else if (cash.test(thisLink)) {
                return <SiCashapp />
            } else if (v.test(thisLink)) {
                return <IoLogoVenmo />
            } else if (u.test(thisLink)) {
                return <FaYoutube />
            } else if (l.test(thisLink)) {
                return <BsLinkedin />
            } else if (y.test(thisLink)) {
                return <BsYelp />
            } else return <FaLink />
        } else return <FaLink />
    }

    getLinks() {
        if (this.state.node && this.state.node.linkArray) {
            let t = /twitter./i
            let fb = /facebook./i
            let inst = /instagram./i
            let cash = /cash.app/i
            let v = /venmo./i
            let u = /youtube./i
            let y = /yelp./i
            let l = /linkedin./i
            let sm = []
            let links = []
            this.state.node.linkArray.forEach(link => {
                if (t.test(link) || fb.test(link) || inst.test(link) || cash.test(link) || v.test(link) || y.test(link) || l.test(link) || u.test(link)) {
                    sm.push((`https://${link}`).replace(/(https|http)/ig, 'https'))
                } else links.push((`https://${link}`).replace(/(https|http)/ig, 'https'))
            })
            if (sm.length + links.length > 0) {
                return <div className='Node-Row'>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><FaLink /></div>
                        <div className='Node-Divider-Text'>Social Media & Links</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Flex-Wrap'>
                        {
                            sm.map((sl, slidx) => {
                                return <div key={`sml-${slidx}`} className='Node-SM-Icons'><a href={sl} target='_blank' rel="noreferrer" title={sl}>{this.getSocialIcon(sl)}</a></div>
                            })
                        }
                    </div>
                    {
                        links.map((link, lidx) => {
                            return <div key={`link-${lidx}`} className='Node-Row Node-Flex'><div className='Node-Addr-Icon'>{this.getSocialIcon(link)}</div><a href={link} target='_blank' rel="noreferrer" className='My-Link'>{link}</a></div>
                        })
                    }
                </div>
            }
        } else return null
    }

    getOpHours() {
        let days = []
        this.state.node.operationArray.forEach(o => days = [...days, ...o.values.map(v => { return { open: o.start || null, close: o.stop || null, day: v } })])
        return days.map((d, dayidx) => {
            return <div key={`day-${dayidx}`} className='Node-Item'>{`${d.day} ${d.open ? `${moment(`2023-01-01 ${d.open}`).format('h:mm A')} - ${moment(`2023-01-01 ${d.close}`).format('h:mm A')}` : 'Closed'}`}</div>
        })
    }

    getMessage() {
        return <div className='Signup-Confirm'>
            <div className='Signup-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Signup-Row Node-Flex-Wrap">
                <div className='Signup-Confirm-Button' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return this.state.node ? <div className={`Node-Container Node-${this.state.route}`}>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {
                this.state.bounce ? this.getBounce() : <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
                    <div className='Node-Content-2'>
                        {this.state.data.imageArray && this.state.data.imageArray.length > 0 ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                            {
                                this.state.data.imageArray.map((ci, imgidx) => {
                                    return <Carousel.Item key={`ci-${imgidx}`}>
                                        <img src={ci.imgUrl ? ci.imgUrl : `https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.data.name || this.state.data.title || ''} />
                                    </Carousel.Item>
                                })
                            }
                        </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                        <div className='Node-Back Node-Flex-Spread'>
                            <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div>
                            {
                                this.state.data.active ? (this.state.data.managed ? <div className='Node-Arrow' style={{ marginRight: '5px', color: 'green' }} onClick={() => this.handleVerified()}><div style={{ marginTop: '-13px' }}><MdOutlineVerified /></div></div> : <div className='Node-Arrow' style={{ marginRight: '5px', color: 'green' }} onClick={() => this.handleVerified()}><div style={{ marginTop: '-13px' }}><MdOutlineVerifiedUser /></div></div>) : <div className='Node-Flex-Spread'>
                                    <div className='Node-Arrow' style={{ marginRight: '5px', color: '$b3b3b3' }} onClick={() => this.handleVerified()}><div style={{ marginTop: '-13px' }}><GoUnverified /></div></div>
                                    <div className='Node-Arrow' style={{ marginRight: '5px', color: '$b3b3b3' }} onClick={() => this.handleVerified(true)}><div style={{ marginTop: '-13px' }}><MdOutlineVerifiedUser /></div></div>
                                </div>
                            }
                        </div>
                        <div className='Node-Content-3'>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Node-Title'>{this.state.data.name ? this.state.data.name : 'Venue'}</div>
                            </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.state.data.genre && this.state.data.genre.map((g, gidx) => {
                                        return <div key={`genre-${gidx}`} className='Node-Item'>{g}</div>
                                    })
                                }
                            </div>
                            {this.state.data.address && <div className='Node-Row Node-Flex'>
                                <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                                <div className='Node-Addr'>
                                    {getAddress(this.state.data.address)}
                                </div>
                            </div>}
                            {this.state.data.operationArray && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><LuCalendarClock /></div>
                                <div className='Node-Divider-Text'>Hours of Operation</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.operationArray && <div className='Node-Row Node-Flex-Left'>
                                {this.getOpHours()}
                            </div>}
                            {this.state.data.rate && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><MdOutlineAttachMoney /></div>
                                <div className='Node-Divider-Text'>Preferred Rate</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.rate && <div className='Node-Row Node-Flex-Wrap'>
                                <div className='Node-Divider-Text'>{this.state.data.rate.rateValue}</div>
                                <div className='Node-Addr-Icon Node-Divider-Text'>Negotiable: {this.state.data.rate.negotiable ? <FaRegCheckSquare /> : <FaSquareXmark />}</div>
                            </div>}
                            {this.state.data.description && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Description</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.description && <div className='Node-Row'>
                                <div className='Node-Details'>{this.state.data.description}</div>
                            </div>}
                            {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>{this.state.data.nodetype} Contact Information</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.communicationArray && this.state.data.communicationArray.length > 0 ? this.state.data.communicationArray.map((comm, cidx) => {
                                return <div key={`comm-${cidx}`} className='Node-Row Node-Flex My-Link'><div style={{ textDecoration: 'none' }} className='My-Link-Text'>{comm.communicationType === 'Phone' ? <FaPhone /> : (comm.communicationType === 'Fax' ? <FaFax /> : <MdEmail />)} {comm.communicationType === 'Email' ? <a href={`mailto:${comm.contact}`}>{comm.contact}</a> : (comm.communicationType === 'Phone' ? <a href={`tel:${comm.contact.replace(/[\D\s]/g, '')}`}>{comm.contact}</a> : comm.contact)} {comm.consent ? <MdPublic /> : <MdPublicOff />}</div></div>
                            }) : null}
                            {this.getLinks()}
                            {this.state.node === 'Venue' && this.state.data.amenities && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Amenities</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.node === 'Venue' && this.state.data.amenities && <div className='Node-Row Node-Flex-Left'>
                                {
                                    this.state.data.amenities.map((am, amidx) => {
                                        return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                                    })
                                }
                            </div>}
                            {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Restrictions</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                                {
                                    this.state.data.ages ? <div className='Node-Item'>{this.state.data.ages}</div> : null
                                }
                                {
                                    this.state.data.restrictions.map((am, amidx) => {
                                        return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                                    })
                                }
                            </div>}
                            {this.state.data.followers && this.state.data.followers > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Followers</div>
                                <div className='Node-Divider'></div>
                                <div>
                                    <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                                    <div className='Node-Heart-Text'>{this.state.data.followers > 1000 ? `${(this.state.data.followers / 1000).toFixed(1)}k` : this.state.data.followers}</div>
                                </div>
                            </div> : null}
                            {this.state.data.stars && this.state.data.stars > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Rating</div>
                                <div className='Node-Divider'></div>
                                <div style={{ marginRight: '5px' }}>
                                    <div className='Node-Stars'><FaStar /></div>
                                    <div className='Node-Stars-Text'>{this.state.data.stars.toFixed(1)}</div>
                                </div>
                            </div> : null}
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text Node-Flex' onClick={() => this.handleAddPost()}>Posts from {this.state.data.name} <div style={{ marginLeft: '5px' }}><BiMessageAdd /></div></div>
                                <div className='Node-Divider'></div>
                                {this.state.data.posts && this.state.data.posts.length > 0 && <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>}
                            </div>
                            {this.state.data.posts && this.state.data.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                                {this.state.data.posts.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((post, pidx) => {
                                    return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{post.post}</div>
                                            <div>
                                                <div style={{ width: '75px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Reviews</div>
                                <div className='Node-Divider'></div>
                                <div className='Node-Divider-Button' onClick={() => this.handleMore('reviews')}>{this.state.more === 'reviews' ? 'Less' : 'More'}</div>
                            </div> : null}
                            {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'reviews' ? ' My-Reviews-More' : ''}`}>
                                {this.state.data.reviews.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((rev, ridx) => {
                                    return <div key={`mrev-${ridx}`} className='My-Review-Container'>
                                        <div className='My-Review-Stars'>
                                            {this.getSelectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`ss-${ss}`} className='Node-Stars'><FaStar /></div>
                                            })}
                                            {this.getUnselectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`us-${ss}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                            })}
                                        </div>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{rev.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${rev.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{rev.comments}</div>
                                            <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(rev.created).format('ll')}</div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            {this.state.data.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/venue/${this.state.data.id}`} /></div>}
                            {this.state.data.doc && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Prood of Ownership/Management</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.doc ? <div className='Node-Row Node-Flex-Wrap My-Link'><a href={this.state.data.doc.docUrl ? this.state.data.doc.docUrl : `https://localtalent-upload.s3.amazonaws.com/public/${this.state.data.doc}`} target="_blank" rel="noreferrer">Proof of Owership or Management</a></div> : null}
                            <Form.Group controlId="connected" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Send Onboarding Email</Form.Label>
                                <Form.Control
                                    placeholder="Liaison/Contact"
                                    type="text"
                                    value={this.state.connected}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="invite" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                                <div className='Node-Flex'>
                                    <Form.Control
                                        placeholder="User Email"
                                        type="text"
                                        value={this.state.invite}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('invite')}
                                    />
                                    <div className='My-Link-Icon' onClick={() => this.handleInvite()}><FaPlusSquare /></div>
                                    {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : (this.state.userPassed ? <div className='My-Link-Icon'><FcInvite /></div> : null)}
                                </div>
                            </Form.Group>
                            <Form.Group controlId="owner" style={{ marginLeft: '5px' }} className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Transfer Owner</Form.Label>
                                <div className='Node-Flex'>
                                    <Form.Control
                                        placeholder="Gigloca User Email"
                                        type="text"
                                        value={this.state.owner}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('owner')}
                                    />
                                    <div className='My-Link-Icon' onClick={() => this.handleChangeOwner()}>{this.state.ownerPassed ? <MdPublishedWithChanges /> : <TbStatusChange />}</div>
                                    {this.state.ownerFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : null}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            }
        </div> : <div style={{ padding: '10%' }} className='Empty-Container Node-Venue'><img className='Node-Logo' src={Logo} alt="Gigloca" /></div>
    }
}

export default withRouter(Contributor)